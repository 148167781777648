import { useRef, useState } from 'react';
import './Accordion.scss';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { CloseIcon } from '../../icons';

const Accordion = ({ title, content, ...props }) => {
  const [open, setOpen] = useState(false);
  const contentRef = useRef(null);

  const getContentHeight = () => {
    return contentRef.current.scrollHeight;
  };

  return (
    <div
      className={'accordion'}
      {...props}
    >
      <div className={`accordion__wrapper ${open ? 'open' : ''}`}>
        <button
          className={'accordion__header'}
          onClick={() => setOpen((prev) => !prev)}
        >
          <div className={'accordion__title'}>{title}</div>
          <div className={`accordion__icon ${open ? 'accordion__icon--rotate' : ''}`}>
            <CloseIcon />
          </div>
        </button>
        <CSSTransition
          in={open}
          timeout={300}
          classNames='accordion__content'
          unmountOnExit
          onEnter={() => {
            contentRef.current.style.height = '0px';
            contentRef.current.style.overflow = 'hidden';
          }}
          onEntering={() => {
            contentRef.current.style.height = `${getContentHeight()}px`;
          }}
          onEntered={() => {
            contentRef.current.style.height = 'auto';
            contentRef.current.style.overflow = 'visible';
          }}
          onExit={() => {
            contentRef.current.style.height = `${getContentHeight()}px`;
            contentRef.current.style.overflow = 'hidden';
          }}
          onExiting={() => {
            contentRef.current.style.height = '0px';
          }}
          onExited={() => {
            contentRef.current.style.height = '0px';
          }}
        >
          <div
            ref={contentRef}
            className={`accordion__content`}
          >
            <div className={'accordion__content-padding'}>
              {Array.isArray(content)
                ? content.map((text) => (
                    <p key={text}>
                      {Array.isArray(text) ? (
                        <ul>
                          {text.map((subtext) => (
                            <li key={subtext}>{subtext}</li>
                          ))}
                        </ul>
                      ) : (
                        text
                      )}
                    </p>
                  ))
                : content}
            </div>
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

export default Accordion;

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};
