import { Route, Routes } from 'react-router-dom';
import { MainPage } from '../pages';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { WIDGET_SCRIPT } from '../configs';

const App = () => {
  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    const s = document.createElement('script');
    s.async = true;
    s.src = WIDGET_SCRIPT[language];
    // s.innerHTML = WIDGET_CODE[language];
    console.log('widget', s);
    document.body.appendChild(s);
    return () => document.body.removeChild(s);
  }, [language]);

  return (
    <Routes>
      <Route
        path={'/'}
        element={<MainPage />}
      />

      {/*<Route*/}
      {/*  path={'/privacy'}*/}
      {/*  element={<PrivacyPage />}*/}
      {/*/>*/}
    </Routes>
  );
};

export default App;
