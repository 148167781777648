import './OurAdvantages.scss';
import { useTranslateData } from '../../hooks/useTranslateData';
import { CheckIcon } from '../../icons';

const OurAdvantages = () => {
  const { LAWYER_ADVANTAGES } = useTranslateData();
  return (
    <ul className={'our-advantages'}>
      {LAWYER_ADVANTAGES.map((item) => (
        <li
          className={'our-advantages__item'}
          key={item.count}
        >
          <div className={'advantage'}>
            <div className={'advantage__inner'}>
              <div className={'advantage__icon'}>
                <CheckIcon />
              </div>
              <div className={'advantage__text'}>
                <strong>{item.count}</strong>&nbsp;
                {item.text}
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default OurAdvantages;
