import './LeadingExperts.scss';
import { useTranslation } from 'react-i18next';
import Section from '../../Section';
import Container from '../../Container';
import ExpertsSlider from './ExpertsSlider';

const LeadingExperts = () => {
  const { t } = useTranslation();

  return (
    <Section
      className={'section-experts'}
      id={'reviews'}
    >
      <Container className={'section-experts__container'}>
        <div className={'section-experts__inner'}>
          <div className={'section-experts__advantages'}>
            <div className={'section-experts__part section-experts__part-left'}>
              <h2 className={'section-experts__title'}>{t('Ведущие эксперты')}</h2>
              <p className={'section-experts__description'}>
                {t(
                  'Юристы по недвижимости, жилищному праву, семейные юристы, юристы по земельному праву, специалисты по банкротству, арбитражные юристы, юристы по трудовому и налоговому праву, корпоративные и арбитражные юристы',
                )}
              </p>
            </div>

            <div className={'section-experts__part section-experts__part-center'}>
              <div className={'section-experts__consultation-info consultation-info'}>
                <div className={'consultation-info__count'}>30+</div>
                <div className={'consultation-info__text'}>{t('сотрудников в команде')}</div>
              </div>
            </div>

            <div className={'section-experts__part section-experts__part-right'}>
              <div className={'section-experts__notification notification-info'}>
                <p>
                  {t(
                    'Каждый юрист в нашей компании имеет свою отдельную специализацию и занимается только тем, в чем хорошо разбирается',
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className={'section-experts__slider'}>
            <ExpertsSlider />
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default LeadingExperts;
