import Modal from '../Modal';
import { useDispatch, useSelector } from 'react-redux';
import { SUCCESS_MODAL } from '../../store/sliceConstants';
import { closeSuccessModal } from '../../actions/modalActions';
import ModalSuccessContent from '../ModalSuccessContent';

const ModalSuccess = () => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.modal[SUCCESS_MODAL].isOpen);

  const handleClose = () => {
    dispatch(closeSuccessModal());
  };

  return (
    <Modal
      isOpen={open}
      onRequestClose={handleClose}
    >
      <ModalSuccessContent onClose={handleClose} />
    </Modal>
  );
};

export default ModalSuccess;
