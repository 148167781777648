import { LOCALES } from '../helpers/constants';

export const normalizePhoneValue = (value) => {
  return value.replace(/\D+/g, '');
};

export const setFormErrorByApi = (setErrors, errors) => {
  for (let error in errors) {
    setErrors(error, {
      type: `${error}-error-from-api`,
      message: errors[error],
    });
  }
};

export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const unFormattedPhoneNumber = (phone) => {
  if (!phone) {
    return null;
  }

  return phone.replace(/\D+/g, '');
};

export const transformLocations = (cities) => {
  if (!cities) return [];
  let CLADR_LENGTH = 13;

  let newFormatCities = [];

  for (let firsLevelCity in cities) {
    const [name, code] = firsLevelCity.split('_');

    newFormatCities.push({
      name,
      code,
      id: uuid(),
      value: name,
    });

    for (let subCities in cities[firsLevelCity]) {
      let currentSubCity = cities[firsLevelCity][subCities];

      currentSubCity.map((item) => {
        let cladr = item.slice(0, CLADR_LENGTH);
        let subCity = item.slice(CLADR_LENGTH);

        newFormatCities.push({
          name: `${name}, ${subCity}`,
          value: `${name}, ${subCity}`,
          id: uuid(),
          cladr: cladr,
        });
      });
    }
  }

  return newFormatCities.sort((a, b) => {
    if (a.name > b.name) {
      return 1;
    }

    if (a.name < b.name) {
      return -1;
    }

    return 0;
  });
};

export const getCurrLocale = (locale) => {
  switch (locale) {
    case LOCALES.uk: {
      return 'ua';
    }
    default: {
      return LOCALES.ru;
    }
  }
};
