import './Section.scss';
import PropTypes from 'prop-types';

const Section = ({ className, withOutPaddingTop, color, ...props }) => {
  return (
    <div
      {...props}
      className={`${className} 
                      section 
                      ${color ? `section--${color}` : ''} 
                      ${withOutPaddingTop ? 'section--without-top' : ''} 
             `}
    />
  );
};

export default Section;

Section.defaultProps = {
  className: '',
  withOutPaddingTop: false,
  color: 'default',
};

Section.propTypes = {
  className: PropTypes.string,
  withOutPaddingTop: PropTypes.bool,
  color: PropTypes.oneOf(['red', 'default']),
};
