import { useForm } from 'react-hook-form';
import './OfferForm.scss';
import { useTranslation } from 'react-i18next';
import FormBorderWrap from '../FormBorderWrap';
import { FormWrapper, InputPhone } from '../Form';
import Button from '../Button';
import FormAdvantages from '../FormAdvantages';
import PrivacyText from '../PrivacyText';
import { useTranslateData } from '../../hooks/useTranslateData';

const OfferForm = () => {
  const { t } = useTranslation();
  const { FORM_ADVANTAGES } = useTranslateData();
  const { ...methods } = useForm();

  const handleSubmit = async (data) => {
    console.log(data);
  };

  return (
    <FormWrapper
      formClassName={'offer-form'}
      onSubmit={handleSubmit}
      methods={methods}
    >
      <FormBorderWrap>
        <div className={'offer-form__inner'}>
          <h2 className={'offer-form__title'}>
            {t('Получите юридическую консультацию и анализ вашей ситуации')}&nbsp;
            <span>{t('бесплатно')}</span>&nbsp;
            {t('в день обращения')}
          </h2>

          <div className={'offer-form__fields'}>
            <InputPhone variant={'default'} />

            <Button
              className={'offer-form__submit'}
              title={'Получить консультацию'}
              size={'big'}
            />
          </div>

          <PrivacyText className={'offer-form__privacy'} />

          <FormAdvantages
            className={'offer-form__advantages'}
            advantages={FORM_ADVANTAGES}
          />
        </div>
      </FormBorderWrap>
    </FormWrapper>
  );
};

export default OfferForm;
