import { createSlice } from '@reduxjs/toolkit';
import { CALLBACK_MODAL, SUCCESS_MODAL } from './sliceConstants';

const initialState = {
  [CALLBACK_MODAL]: {
    isOpen: false,
  },
  [SUCCESS_MODAL]: {
    isOpen: false,
  },
};

export const modalSlice = createSlice({
  name: 'modal_slice',
  initialState,
  reducers: {
    openModal: (state, { payload }) => {
      state[payload.type].isOpen = true;
      // state[payload.type].payload = payload.payload;
    },
    closeModal: (state, { payload }) => {
      state[payload.type].isOpen = false;
      // state[payload.type].payload = null;
    },
  },
});

export const { closeModal, openModal } = modalSlice.actions;

export default modalSlice.reducer;
