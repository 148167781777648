import { LOCATION_DEFAULT_IN_LEAD, NAME_DEFAULT_IN_LEAD, TEXT_DEFAULT_IN_LEAD, WM_ID } from '../configs';

const LEXPROFIT_URL = 'https://api.lexprofit.ru/v1';

export async function sendFormDataToLP(data, language) {
  console.log('data', data);
  const body = new URLSearchParams({
    location: data.location?.toString() || LOCATION_DEFAULT_IN_LEAD,
    phone: data.phone.toString(),
    wm_id: WM_ID.toString(),
    url: window.location.href,
    question: data.question ?? language === 'ru' ? TEXT_DEFAULT_IN_LEAD.ru : TEXT_DEFAULT_IN_LEAD.uk,
  });
  console.log('body', body);
  if (NAME_DEFAULT_IN_LEAD !== '') {
    body.set('name', NAME_DEFAULT_IN_LEAD);
  }

  const result = await fetch(LEXPROFIT_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body,
  });
  console.log('result', result);
  const response = await result.json();
  console.log('response', response);
  return 'success' in response;
}
