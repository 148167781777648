import { ReactComponent as Tick } from '.././icons/tick.svg';
import { ReactComponent as HeadSvg } from '.././icons/head.svg';
import { ReactComponent as SearchSvg } from '.././icons/search.svg';

import { ReactComponent as Basket } from '.././icons/services/basket.svg';
import { ReactComponent as Debt } from '.././icons/services/debt.svg';
import { ReactComponent as CreditCard } from '.././icons/services/credit-card.svg';
import { ReactComponent as Accident } from '.././icons/services/accident.svg';
import { ReactComponent as Law } from '.././icons/services/law.svg';
import { ReactComponent as Derrick } from '.././icons/services/derrick.svg';
import { ReactComponent as FloorPlans } from '.././icons/services/floor-plans.svg';
import { ReactComponent as Work } from '.././icons/services/work.svg';
import { ReactComponent as Stamp } from '.././icons/services/stamp.svg';
import { ReactComponent as Increase } from '.././icons/services/increase.svg';
import { ReactComponent as Text } from '.././icons/services/text.svg';
import { ReactComponent as Family } from '.././icons/services/family.svg';
import { ReactComponent as Tel } from '.././icons/tel.svg';

//services icons

export const TelIcon = (props) => {
  return <Tel {...props} />;
};

export const BasketIcon = (props) => {
  return <Basket {...props} />;
};

export const DebtIcon = (props) => {
  return <Debt {...props} />;
};

export const CreditCardIcon = (props) => {
  return <CreditCard {...props} />;
};

export const AccidentIcon = (props) => {
  return <Accident {...props} />;
};

export const LawIcon = (props) => {
  return <Law {...props} />;
};

export const DerrickIcon = (props) => {
  return <Derrick {...props} />;
};

export const FloorPlansIcon = (props) => {
  return <FloorPlans {...props} />;
};

export const WorkIcon = (props) => {
  return <Work {...props} />;
};

export const StampIcon = (props) => {
  return <Stamp {...props} />;
};

export const IncreaseIcon = (props) => {
  return <Increase {...props} />;
};

export const TextIcon = (props) => {
  return <Text {...props} />;
};

export const FamilyIcon = (props) => {
  return <Family {...props} />;
};

//end services

export const CheckIcon = (props) => {
  return <Tick {...props} />;
};

export const HeadIcon = (props) => {
  return <HeadSvg {...props} />;
};

export const SearchIcon = (props) => {
  return <SearchSvg {...props} />;
};

export const WhatsappIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 50 50'
      width='20px'
      height='20px'
    >
      <path
        fill='#ffffff'
        d='M25,2C12.318,2,2,12.318,2,25c0,3.96,1.023,7.854,2.963,11.29L2.037,46.73c-0.096,0.343-0.003,0.711,0.245,0.966 C2.473,47.893,2.733,48,3,48c0.08,0,0.161-0.01,0.24-0.029l10.896-2.699C17.463,47.058,21.21,48,25,48c12.682,0,23-10.318,23-23 S37.682,2,25,2z M36.57,33.116c-0.492,1.362-2.852,2.605-3.986,2.772c-1.018,0.149-2.306,0.213-3.72-0.231 c-0.857-0.27-1.957-0.628-3.366-1.229c-5.923-2.526-9.791-8.415-10.087-8.804C15.116,25.235,13,22.463,13,19.594 s1.525-4.28,2.067-4.864c0.542-0.584,1.181-0.73,1.575-0.73s0.787,0.005,1.132,0.021c0.363,0.018,0.85-0.137,1.329,1.001 c0.492,1.168,1.673,4.037,1.819,4.33c0.148,0.292,0.246,0.633,0.05,1.022c-0.196,0.389-0.294,0.632-0.59,0.973 s-0.62,0.76-0.886,1.022c-0.296,0.291-0.603,0.606-0.259,1.19c0.344,0.584,1.529,2.493,3.285,4.039 c2.255,1.986,4.158,2.602,4.748,2.894c0.59,0.292,0.935,0.243,1.279-0.146c0.344-0.39,1.476-1.703,1.869-2.286 s0.787-0.487,1.329-0.292c0.542,0.194,3.445,1.604,4.035,1.896c0.59,0.292,0.984,0.438,1.132,0.681 C37.062,30.587,37.062,31.755,36.57,33.116z'
      />
    </svg>
  );
};

export const TelegramIcon = () => {
  return (
    <svg
      className='t-sociallinks__svg'
      width='20px'
      height='20px'
      viewBox='0 0 100 100'
      fill='#342433'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M50 100c27.614 0 50-22.386 50-50S77.614 0 50 0 0 22.386 0 50s22.386 50 50 50Zm21.977-68.056c.386-4.38-4.24-2.576-4.24-2.576-3.415 1.414-6.937 2.85-10.497 4.302-11.04 4.503-22.444 9.155-32.159 13.734-5.268 1.932-2.184 3.864-2.184 3.864l8.351 2.577c3.855 1.16 5.91-.129 5.91-.129l17.988-12.238c6.424-4.38 4.882-.773 3.34.773l-13.49 12.882c-2.056 1.804-1.028 3.35-.129 4.123 2.55 2.249 8.82 6.364 11.557 8.16.712.467 1.185.778 1.292.858.642.515 4.111 2.834 6.424 2.319 2.313-.516 2.57-3.479 2.57-3.479l3.083-20.226c.462-3.511.993-6.886 1.417-9.582.4-2.546.705-4.485.767-5.362Z'
        fill='#3a1a93'
      ></path>
    </svg>
  );
};

export const VkIcon = () => {
  return (
    <svg
      className='t-sociallinks__svg'
      width='30px'
      height='30px'
      viewBox='0 0 100 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M50 100c27.614 0 50-22.386 50-50S77.614 0 50 0 0 22.386 0 50s22.386 50 50 50ZM25 34c.406 19.488 10.15 31.2 27.233 31.2h.968V54.05c6.278.625 11.024 5.216 12.93 11.15H75c-2.436-8.87-8.838-13.773-12.836-15.647C66.162 47.242 71.783 41.62 73.126 34h-8.058c-1.749 6.184-6.932 11.805-11.867 12.336V34h-8.057v21.611C40.147 54.362 33.838 48.304 33.556 34H25Z'
        fill='#ffffff'
      ></path>
    </svg>
  );
};

export const ArrowRightIcon = (props) => {
  return (
    <svg
      className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc'
      focusable='false'
      aria-hidden='true'
      viewBox='0 0 24 24'
      data-testid='ArrowForwardIosOutlinedIcon'
      {...props}
    >
      <path d='M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z'></path>
    </svg>
  );
};

export const ArrowLeftIcon = (props) => {
  return (
    <svg
      className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc'
      focusable='false'
      aria-hidden='true'
      viewBox='0 0 24 24'
      data-testid='ArrowBackIosNewOutlinedIcon'
      {...props}
    >
      <path d='M17.77 3.77 16 2 6 12l10 10 1.77-1.77L9.54 12z'></path>
    </svg>
  );
};

export const CloseIcon = (props) => {
  return (
    <svg
      className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc'
      focusable='false'
      aria-hidden='true'
      viewBox='0 0 24 24'
      data-testid='CloseOutlinedIcon'
      {...props}
    >
      <path d='M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z'></path>
    </svg>
  );
};

export const CertificateIcon = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      id='Layer_1'
      data-name='Layer 1'
      viewBox='0 0 101 101'
      {...props}
    >
      <title>log_Artboard 2 copy 11</title>
      <path d='M83.5,16h-66A1.5,1.5,0,0,0,16,17.5v67A1.5,1.5,0,0,0,17.5,86h66A1.5,1.5,0,0,0,85,84.5v-67A1.5,1.5,0,0,0,83.5,16ZM82,83H19V19H82Z' />
      <path d='M42.81,44.11,41.67,51a2.32,2.32,0,0,0,.93,2.28,2.24,2.24,0,0,0,2.37.16l6-3.19,5.71,3.14a2.24,2.24,0,0,0,2.39-.13A2.32,2.32,0,0,0,60,51l-1.13-6.9,4.78-4.88a2.33,2.33,0,0,0,.55-2.38,2.28,2.28,0,0,0-1.82-1.55l-6.55-1-2.94-6.24a2.27,2.27,0,0,0-4.12,0h0l-2.94,6.24-6.55,1a2.28,2.28,0,0,0-1.83,1.55A2.33,2.33,0,0,0,38,39.23Zm4.29-7a1.5,1.5,0,0,0,1.13-.84l2.63-5.58,2.63,5.58a1.5,1.5,0,0,0,1.13.84l6,.92-4.4,4.49a1.5,1.5,0,0,0-.41,1.29l1,6.25-5.12-2.82a1.49,1.49,0,0,0-1.42,0l-5.45,2.89,1-6.31a1.5,1.5,0,0,0-.41-1.29l-4.4-4.49Z' />
      <path d='M58.5,64h-16a1.5,1.5,0,0,0,0,3h16a1.5,1.5,0,0,0,0-3Z' />
      <path d='M58.5,73h-16a1.5,1.5,0,0,0,0,3h16a1.5,1.5,0,0,0,0-3Z' />
    </svg>
  );
};

export const PinIcon = (props) => {
  return (
    <svg
      className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc'
      focusable='false'
      aria-hidden='true'
      viewBox='0 0 24 24'
      data-testid='RoomOutlinedIcon'
      {...props}
    >
      <path d='M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zM7 9c0-2.76 2.24-5 5-5s5 2.24 5 5c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z'></path>
      <circle
        cx='12'
        cy='9'
        r='2.5'
      ></circle>
    </svg>
  );
};

export const SuccessIcon = (props) => {
  return (
    <svg
      className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc'
      focusable='false'
      aria-hidden='true'
      viewBox='0 0 24 24'
      data-testid='CheckCircleOutlinedIcon'
      {...props}
    >
      <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z'></path>
    </svg>
  );
};
