import './Faq.scss';
import Section from '../../Section';
import Container from '../../Container';
import { useTranslateData } from '../../../hooks/useTranslateData';
import Accordion from '../../Accordion';

const Faq = () => {
  const { FAQ } = useTranslateData();

  return (
    <Section className={'section-faq'}>
      <Container className={'section-faq__container'}>
        <div className={'section-faq__inner'}>
          {FAQ.map((item) => (
            <Accordion
              key={item.question}
              title={item.question}
              content={item.answer}
            />
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default Faq;
