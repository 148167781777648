import { closeModal, openModal } from '../store/modalSlice';
import { CALLBACK_MODAL, SUCCESS_MODAL } from '../store/sliceConstants';

export const openCallbackModal = () => {
  return openModal({
    type: CALLBACK_MODAL,
  });
};

export const closeCallbackModal = () => {
  return closeModal({
    type: CALLBACK_MODAL,
  });
};

export const openSuccessModal = () => {
  return openModal({
    type: SUCCESS_MODAL,
  });
};

export const closeSuccessModal = () => {
  return closeModal({
    type: SUCCESS_MODAL,
  });
};
