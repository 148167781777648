import './LeadingExperts.scss';
import Slider from '../../Slider';
import SliderItem from '../../SliderItem';
import { useTranslateData } from '../../../hooks/useTranslateData';

const ExpertsSlider = () => {
  const { EXPERTS } = useTranslateData();

  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    lazyLoad: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          lazyLoad: true,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          lazyLoad: true,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          lazyLoad: true,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
          lazyLoad: true,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <Slider
      className={'expert-slider'}
      settings={settings}
    >
      {EXPERTS.map((item) => (
        <SliderItem key={item.name}>
          <div className={'expert-card'}>
            <img
              className={'expert-card__image'}
              src={item.photo}
              alt={item.name}
            />
            <div className={'expert-card__inner'}>
              <div className={'expert-card__name'}>{item.name}</div>
              <div className={'expert-card__description'}>{item.description}</div>
            </div>
          </div>
        </SliderItem>
      ))}
    </Slider>
  );
};

export default ExpertsSlider;
