import Modal from '../Modal';
import { useDispatch, useSelector } from 'react-redux';
import { CALLBACK_MODAL } from '../../store/sliceConstants';
import { closeCallbackModal } from '../../actions/modalActions';
import ModalCallbackForm from '../ModalCallbackForm';

const ModalCallback = () => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.modal[CALLBACK_MODAL].isOpen);

  const handleClose = () => {
    dispatch(closeCallbackModal());
  };

  return (
    <Modal
      isOpen={open}
      size={'xs'}
      withoutPadding
      onRequestClose={handleClose}
    >
      <ModalCallbackForm handleClose={handleClose} />
    </Modal>
  );
};

export default ModalCallback;
