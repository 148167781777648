function localStorageProvider() {
  const provider = window.localStorage;

  return {
    get(key) {
      try {
        const data = JSON.parse(provider.getItem(key));

        //from if we set jwt token
        if (data?.expire) {
          if (data.expire >= Date.now()) {
            return data.value;
          }

          this.remove(key);
        }

        return data;
      } catch (error) {
        return null;
      }
    },
    set(key, value, expire = null) {
      let data = value;

      //from if we set jwt token
      if (expire && typeof expire === 'number') {
        data = {
          value,
          expire: new Date().getTime() + expire * 3600 * 1000,
        };
      }

      provider.setItem(key, JSON.stringify(data));
    },
    remove(key) {
      provider.removeItem(key);
    },
    clear() {
      provider.clear();
    },
  };
}

function sessionStorageProvider() {
  const provider = window.sessionStorage;

  return {
    get(key) {
      try {
        return JSON.parse(provider.getItem(key));
      } catch (error) {
        return null;
      }
    },
    set(key, value) {
      provider.setItem(key, JSON.stringify(value));
    },
    remove(key) {
      provider.removeItem(key);
    },
    clear() {
      provider.clear();
    },
  };
}

export default {
  local: localStorageProvider(),
  session: sessionStorageProvider(),
};
