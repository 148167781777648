import './Contacts.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Section from '../../Section';
import Container from '../../Container';
import { LogoRuWhiteIcon, LogoUaWhiteIcon } from '../../../images';
import { useTranslateData } from '../../../hooks/useTranslateData';
import { LOCALES } from '../../../helpers/constants';
import { openCallbackModal } from '../../../actions/modalActions';
import PhoneLink from '../../PhoneLink/PhoneLink';

const Contacts = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();

  const handleOpenCallbackModal = () => {
    dispatch(openCallbackModal());
  };

  const { LEGAL_SERVICES, INDIVIDUAL_SERVICES } = useTranslateData();

  return (
    <Section className={'section-contacts'}>
      <Container>
        <div className={'section-contacts__inner'}>
          <div className='section-contacts__part section-contacts__addresses'>
            <div className={'section-contacts__logo'}>
              {language === LOCALES.ru ? <LogoRuWhiteIcon /> : <LogoUaWhiteIcon />}
            </div>
            <div className={'section-contacts__phone'}>
              <PhoneLink />
            </div>
            <div className={'section-contacts__callback'}>
              <button
                onClick={handleOpenCallbackModal}
                className={'section-contacts__callback-btn'}
              >
                {t('Обратный звонок')}
              </button>
            </div>
          </div>
          <div className='section-contacts__part section-contacts__individual-services individual-services'>
            <p className={'individual-services__title'}>{t('Услуги физическим лицам')}</p>
            <ul className={'individual-services-list'}>
              {INDIVIDUAL_SERVICES.map((item) => (
                <li
                  key={item.text}
                  className={'individual-services-list__item'}
                >
                  {item.text}
                </li>
              ))}
            </ul>
          </div>
          <div className='section-contacts__part section-contacts__legal-services legal-services'>
            <p className={'legal-services__title'}>{t('Услуги юридическим лицам')}</p>
            <ul className={'legal-services-list'}>
              {LEGAL_SERVICES.map((item) => (
                <li
                  key={item.text}
                  className={'legal-services-list__item'}
                >
                  {item.text}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Contacts;
