import PropTypes from 'prop-types';
import './ModalCallbackForm.scss';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FormWrapper, Input, InputPhone } from '../Form';
import Button from '../Button';
import { CURRENT_IP, INPUT_RULES } from '../../helpers/constants';
import storage from '../../helpers/storageService';
import { THIRD_PARTY_VARIABLES } from '../../utils/thirdPartyVariables';
import { openSuccessModal } from '../../actions/modalActions';
import { sendFormDataToLP } from '../../services/api';
import { handleSubmitLead } from '../../helpers/analitics';

const ModalCallbackForm = ({ handleClose }) => {
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const currentIp = storage.session.get(CURRENT_IP);

  const { reset, ...methods } = useForm({
    defaultValues: {
      ip: currentIp,
      ...THIRD_PARTY_VARIABLES,
    },
  });
  const handleSubmit = async (data) => {
    console.log('start submit', data);

    try {
      sendFormDataToLP(data, language)
        .then((result) => {
          if (result) {
            handleSubmitLead();
            reset();
            handleClose();
            dispatch(openSuccessModal());
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (error) {
      console.log(Object.entries(error));
    }
  };

  return (
    <FormWrapper
      onSubmit={handleSubmit}
      methods={methods}
      formClassName={'callback-form'}
    >
      <div className={'callback-form__inner'}>
        <div className={'callback-form__title'}>{t('Заполните форму на бесплатную консультацию')}</div>
        <div className={'callback-form__description'}>
          {t('Наш специалист свяжется с вами в течении 5 минут и подберет юриста, который ответит на ваши вопросы')}
        </div>
        <div className={'callback-form__fields'}>
          <InputPhone className={'callback-form__field'} />

          <Input
            name={'question'}
            multiline
            rows={10}
            rules={INPUT_RULES.question}
            className={'callback-form__field'}
            placeholder={'Опишите ваш вопрос (это не обязательное поле, все вопросы можете задать по телефону)'}
          />

          <Button
            className={'callback-form__submit-btn'}
            title={'Отправить заявку'}
            type={'submit'}
            animation
          />
        </div>

        <div className={'callback-form__privacy'}>
          {t('Нажимая на кнопку вы соглашаетесь с обработкой персональных данных и политикой конфиденциальности')}
        </div>
      </div>
    </FormWrapper>
  );
};

export default ModalCallbackForm;

ModalCallbackForm.propTypes = {
  handleClose: PropTypes.func,
};
