import './AboutLawyers.scss';
import { useTranslation } from 'react-i18next';
import Section from '../../Section';
import Container from '../../Container';
import FirstImg from '../../../images/aboutLawyer/1.jpg';
import SecondImg from '../../../images/aboutLawyer/2.jpg';
import { useContext } from 'react';
import { CityContext } from '../../../context/context';

const AboutLawyers = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { CITY_NAME } = useContext(CityContext);

  return (
    <Section className={'section-about-lawyers'}>
      <Container className={'section-about-lawyers__container'}>
        <div className={'section-about-lawyers__inner'}>
          <div className={'section-about-lawyers__info'}>
            <div className={'section-about-lawyers__title'}>
              {t('В «Лиг Офис» работают только те юристы CITY.RODIT, кто имеет:', {
                city: CITY_NAME[language].RODIT,
              })}
            </div>
            <ul className={'section-about-lawyers-list'}>
              <li className={'section-about-lawyers-list__item'}>- {t('опыт')},</li>
              <li className={'section-about-lawyers-list__item'}>- {t('судебную практику')},</li>
              <li className={'section-about-lawyers-list__item'}>- {t('добросовестен в работе')}!</li>
            </ul>
          </div>
          <div className={'section-about-lawyers__texts'}>
            <p>
              {t('Мы берем в команду только')}&nbsp;
              <strong>{t('юристов CITY.RODIT', { city: CITY_NAME[language].RODIT })}</strong>&nbsp;
              {t(
                'с положительным опытом! Это - судебные юристы! За плечами у них годы работы и победы в судах. Для нас и для вас это имеет значение!',
              )}
            </p>

            <p>
              {t(
                'Работа в суде дает юристу самые важные профессиональные качества: увидеть шансы выигрыша судебного спора, разработать стратегию победы, выдать правильную реакцию на заявления наших противников в споре, успешно обжаловать решение суда или неправомерные действия судьи!',
              )}
            </p>
            <p>
              {t('С первой же')}&nbsp;
              <strong>{t('консультации юриста в офисе')}</strong>&nbsp;
              {t('"Лигл Офис" вы встречаетесь именно с таким специалистом!')}
            </p>
          </div>
        </div>

        <div className={'divider'}></div>

        <div className={'section-about-lawyers__more-info'}>
          <div className={'about-lawyers'}>
            <div className={'about-lawyers__photo'}>
              <img
                src={FirstImg}
                alt='Lawyers'
              />
            </div>
            <div className={'about-lawyers__texts'}>
              <h2 className={'about-lawyers__title'}>
                {t('Либо подробно расскажем вам, как действовать, либо самостоятельно решим ваш вопрос "от" и "до"!')}
              </h2>
              <p>
                {t(
                  'На консультации рассказываем по шагам - как вам поступить, куда обратиться, какое заявление написать - хотите действовать сами - полный алгоритм дадим вам, расскажем, где могут быть "подводные камни"!',
                )}
              </p>
              <p>
                {t(
                  'Если вопрос сложный и нужны наши профессиональные знания – мы сделаем работу от начала до результата! В вопросах судебной защиты ваших прав, при согласовании в инстанциях, при поверке сделки с недвижимостью - доверьте нам, юристам в CITY.PREDLOJ эту работу!',
                  { city: CITY_NAME[language].PREDLOJ },
                )}
              </p>

              <ul>
                <li>- {t('Мы работаем, согласовывая с вами каждый шаг;')}</li>
                <li>- {t('Предоставляем отчетность по каждому действию;')}</li>
                <li>- {t('Консультируем по возникающим вопросам в ходе дела;')}</li>
                <li>- {t('Всегда намечаем дедлайны этапов работы.')}</li>
              </ul>
            </div>
          </div>

          <div className='lawyer-callback'>
            <div className={'lawyer-callback__photo'}>
              <img
                src={SecondImg}
                alt='LawyerCallback'
              />
            </div>
            <div className={'lawyer-callback__texts'}>
              <h2 className={'lawyer-callback__title'}>
                {t('Юрист в CITY.PREDLOJ может позвонить вам сам! Закажите звонок!', {
                  city: CITY_NAME[language].PREDLOJ,
                })}
              </h2>

              <p>
                <strong>{t('Юрист в офисе')}</strong>&nbsp;
                {t('- только в рабочее время. Но вы можете написать нам и заказать звонок - с сайта в любое время!')}
              </p>

              <ul>
                <li>{t('Мы получим ваш вопрос и подберем юриста.')}</li>
                <li>
                  {t(
                    'Мы позвоним вам и запишем на консультацию в офис к профессионалу-юристу, в зависимости от вопроса - напомним вам взять документы по вашему делу, для того, чтобы юрист посмотрел их и составил мнение о ситуации.',
                  )}
                </li>
                <li>
                  {t(
                    'Консультация юриста в офисе "Лигл Офис" вас не обязывает к заключению договора с нами на услуги! Мы - рады помочь, научить, подсказать, предупредить вас об опасностях, если они есть!',
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default AboutLawyers;
