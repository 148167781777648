import './Offer.scss';
import Container from '../../Container';
import { useTranslation } from 'react-i18next';
import { HeadIcon, SearchIcon } from '../../../icons';
import OfferForm from '../../OfferForm';

const Offer = () => {
  const { t } = useTranslation();

  const OFFER_ADVANTAGES = [
    {
      icon: t('лет'),
      type: 'year',
      number: '14',
      text: [t('на рынке'), <br key={'services'} />, t('юридических услуг')],
    },
    {
      icon: <HeadIcon />,
      type: 'lawyers',
      number: '30+',
      text: t('юристов разных практик и специализаций в команде'),
    },
    {
      icon: '+',
      type: 'tribunal',
      number: '2000',
      text: t('судов успешно завершено в пользу клиентов'),
    },
  ];

  return (
    <div className={'offer'}>
      <Container className={'offer__container'}>
        <div className={'offer__inner'}>
          <div className={'offer__search'}>
            <div className={'offer__search-icon'}>
              <SearchIcon />
            </div>
            <div className={'offer__search-text'}>{t('Лидирующая юридическая компания Российской Федерации')}</div>
          </div>

          <h1 className={'offer__title'}>{t('Все виды юридических услуг для граждан и бизнеса')}</h1>
          <p className={'offer__description'}>{t('Профессионально. Надежно. С гарантией результата.')}</p>

          <ul className={'offer__advantages-list offer-advantages'}>
            {OFFER_ADVANTAGES.map((adv) => (
              <li
                className={'offer-advantages__item'}
                key={adv.number}
              >
                <div className={'advantage'}>
                  <div className={'advantage__number'}>
                    <div className={`advantage__badge ${adv.type}`}>{adv.icon}</div>
                    {adv.number}
                  </div>
                  <div className={'advantage__text'}>{adv.text}</div>
                </div>
              </li>
            ))}
          </ul>

          <OfferForm />
        </div>
      </Container>
      {/*<div className={`offer__footer`}></div>*/}
    </div>
  );
};

export default Offer;
