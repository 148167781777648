import PropTypes from 'prop-types';
import ReactSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import './Slider.scss';
import { ArrowLeftIcon, ArrowRightIcon } from '../../icons';

const PrevArrow = ({ className, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={className}
    >
      <ArrowLeftIcon />
    </button>
  );
};

const NextArrow = ({ className, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={className}
    >
      <ArrowRightIcon />
    </button>
  );
};

NextArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

PrevArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

const Slider = ({ children, className, settings }) => {
  let fullSettings = {
    ...settings,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <ReactSlider
      className={className}
      {...fullSettings}
    >
      {children}
    </ReactSlider>
  );
};

export default Slider;

Slider.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  settings: PropTypes.object,
};
