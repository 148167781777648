import { useTranslation } from 'react-i18next';
import {
  AccidentIcon,
  BasketIcon,
  CreditCardIcon,
  DebtIcon,
  DerrickIcon,
  FamilyIcon,
  FloorPlansIcon,
  IncreaseIcon,
  LawIcon,
  StampIcon,
  TextIcon,
  WorkIcon,
} from '../icons';

import FirstLawyer from '../images/lawyers/1.jpg';
import SecondLawyer from '../images/lawyers/2.jpg';
import ThreeLawyer from '../images/lawyers/3.jpg';
import FourLawyer from '../images/lawyers/4.jfif';
import FiveLawyer from '../images/lawyers/5.jpg';
import SixLawyer from '../images/lawyers/6.jpg';
import SevenLawyer from '../images/lawyers/7.jpg';
import { useContext } from 'react';
import { CityContext } from '../context/context';

export const useTranslateData = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { CITY_NAME } = useContext(CityContext);

  const FORM_ADVANTAGES = [
    {
      text: t('В течении 5 минут с вами свяжется юрист и ответит на все вопросы'),
    },
    { text: t('Честно расскажет о возможных рисках, перспективах и вариантах решений') },
    { text: t('Предоставит рекомендации к действию или возьмет решение всех вопросов на себя') },
  ];

  const SERVICES = [
    {
      icon: <IncreaseIcon />,
      title: t('Сопровождение бизнеса'),
      description: t('Комплексное юридическое обслуживание. Правовой аудит'),
    },
    {
      icon: <FamilyIcon />,
      title: t('Семейный юрист'),
      description: t('Бракоразводный процесс, алименты, установление отцовства'),
    },
    {
      icon: <StampIcon />,
      title: t('Банкротство и ликвидация юр. лиц'),
      description: t('Анализ ситуации, подготовка документов, публикации в СМИ, уведомление кредиторов'),
    },
    {
      icon: <DebtIcon />,
      title: t('Банкротство физических лиц'),
      description: t('Анализ ситуации, подготовка документов, публикации в СМИ, уведомление кредиторов'),
    },
    {
      icon: <FloorPlansIcon />,
      title: t('Недвижимость и перепланировки'),
      description: t('Оформление и сопровождение сделок по недвижимости. Узаконивание перепланировок'),
    },
    {
      icon: <CreditCardIcon />,
      title: t('Взыскание долгов'),
      description: t('Взыскание дебиторской задолженности, долгов по договорам и распискам'),
    },
    {
      icon: <WorkIcon />,
      title: t('Регистрация бизнеса'),
      description: t('Регистрация ООО, ФОП и другие процедуры'),
    },
    {
      icon: <TextIcon />,
      title: t('Составление и анализ документов'),
      description: t('Разработка и анализ любых договоров, соглашений, исков, заявлений'),
    },
    {
      icon: <LawIcon />,
      title: t('Представительство и защита в суде'),
      description: t('Представляем ваши интересы в арбитраже и судах общей юрисдикции'),
    },
    {
      icon: <BasketIcon />,
      title: t('Защита прав потребителей'),
      description: t('Расторжение договора о покупке или оформлении услуги, возврат денежных средств'),
    },
    {
      icon: <AccidentIcon />,
      title: t('Автоюрист. споры по ДТП'),
      description: t('Экспертиза и оценка ущерба после ДТП. Претензии по качеству ремонта авто. Споры с ГАИ'),
    },
    {
      icon: <DerrickIcon />,
      title: t('Споры с застройщиками'),
      description: t('Взыскание неустойки. Споры по качеству строительства'),
    },
  ];

  const LAWYER_ADVANTAGES = [
    {
      count: t('12 лет'),
      text: t('на рынке юридических услуг'),
    },
    {
      count: t('30 юристов'),
      text: t('разных практик и специализаций в команде'),
    },
    {
      count: t('10 000 клиентов'),
      text: t('прошло через наших юристов'),
    },
    {
      count: t('2000 судов'),
      text: t('было успешно завершено в пользу клиентов'),
    },
    {
      count: t('1,5 млрд. грн.'),
      text: t('взыскали и защитили для наших клиентов'),
    },
    {
      count: t('3 500 консультаций'),
      text: t('за год проводят наши юристы'),
    },
  ];

  const ABOUT_ADVANTAGES = [
    {
      count: t('13 лет'),
      text: t('лет на рынке юридических услуг'),
    },
    {
      count: t('2000+'),
      text: t('судов было успешно завершено в пользу клиентов'),
    },
    {
      count: t('1,5 млрд'),
      text: t('денежных средств взыскали и защитили для наших клиентов'),
    },
  ];

  const EXPERTS = [
    {
      name: t('Вадим Бадмаев'),
      photo: FirstLawyer,
      description: t('Директор "Лигл Офис"'),
    },
    {
      name: t('Екатерина Васильева'),
      photo: SecondLawyer,
      description: t('Руководитель отдела по работе с юридическими лицами'),
    },
    {
      name: t('Наталья Кучмыстая'),
      photo: ThreeLawyer,
      description: t('Специализация: наследственные споры'),
    },
    {
      name: t('Елена Никифорова'),
      photo: FourLawyer,
      description: t('Ведущий юрист по работе с застройщиками'),
    },
    {
      name: t('Дмитрий Жоголев'),
      photo: FiveLawyer,
      description: t('Помощник юриста'),
    },
    {
      name: t('Иван Живодерников'),
      photo: SixLawyer,
      description: t('Юрист - консультант'),
    },
    {
      name: t('Александр Карнаухов'),
      photo: SevenLawyer,
      description: t('Юрист - консультант'),
    },
  ];

  const WORK_STEPS = [
    {
      title: t('1) Консультация профильного юриста в офисе'),
      texts: [
        t(
          'Юридическая консультация. Она служит цели разбора ситуации, анализу документов, поиску путей решения вопроса.',
        ),
        t(
          'Юристы разъясняют правила законодательства, рассказывают примеры из судебной практики по аналогичным спорам, перечисляют риски клиента, которые могут привести к неудаче.',
        ),
      ],
    },
    {
      title: t('2) Подготовка к судебному спору, досудебный порядок'),
      texts: [
        t('Юристы CITY.RODIT в компании «Лигл Офис» не упускают возможности пройти досудебный порядок спора.', {
          city: CITY_NAME[language].RODIT,
        }),
        t(
          'Во-первых, ваш вопрос может решиться без суда, во-вторых - в некоторых случаях это увеличит сумму взыскания для нашего клиента в суде, если досудебный порядок не решил спор.',
        ),
      ],
    },
    {
      title: t('3) Юрист ведет судебный процесс, защищает ваши интересы в нём'),
      texts: [
        t(
          'По вашему согласию - мы можем вести ваше дело по доверенности, без вашего присутствия в каждом судебном заседании.',
        ),
        t(
          'О ходе дела мы информируем вас каждый раз, согласовываем с вами наши действия и знакомим вас с документами дела. По вашему желанию и наличию времени - мы можем посещать судебные заседания вместе. Вы лично будете в курсе происходящего - мгновенно, сразу же советуясь с юристом, давая оценку событиям.',
        ),
      ],
    },
    {
      title: t('4) Юристы CITY.RODIT в "Лигл Офис" ведут исполнительное производство', {
        city: CITY_NAME[language].RODIT,
      }),
      texts: [
        t('Мы поддержим вас и в ситуации - после победы в суде!'),
        t(
          'Ведь при взыскании денег через суд, при возврате права собственности, при последующем ведении исполнительного производства, вам потребуется помощь вашего решительного и "пробивного" юриста!',
        ),
        t(
          'Тот же подход! Если хотите самостоятельно вести исполнение судебного решения - научим! Либо - пойдем и сделаем! Добьёмся перевода денег на ваш счёт!',
        ),
      ],
    },
  ];

  const FAQ = [
    {
      question: t('Если у вас сложный вопрос, который потребует от нас тщательно изучить документы...'),
      answer: [
        t(
          'Если у вас сложное судебное дело, материалов которого у вас нет на руках, или есть вопрос по отчетности, но запущенно велись документы бизнеса, то для полноценной оценки вопроса и детальной консультации - мы возьмем паузу. Нам нужно время, чтобы изучить документы, посетить суд и ознакомиться с вашим делом, посмотреть практику, посовещаться с коллегами.',
        ),
        t(
          'После выяснения ситуации мы свяжемся с вами, пригласим на встречу и подробно изложим позицию по вашему вопросу: какова ситуация, какие права есть у сторон, как может развернуться ваше дело в суде или без суда, какие риски вас могут ожидать.',
        ),
        t(
          'Мы можем сделать подготовку в краткие сроки, но не можем консультировать вас, не зная подлинной ситуации. Это вопросы профессионализма!',
        ),
      ],
    },
    {
      question: t('Гарантии юристов «Лигл Офис»'),
      answer: [
        t('Мы работаем честно и никогда не гарантируем вам за других лиц. Но за себя - можем поручиться:'),
        [
          t(
            'Гарантируем вам при обращении подробный и полный ответ юриста на консультации: плюсы и минусы ситуации, ее возможные развития в будущем.',
          ),
          t('Гарантируем вам приём у юриста в CITY.PREDLOJ именно в то время, на которое вы записались.', {
            city: CITY_NAME[language].PREDLOJ,
          }),
          t('Гарантируем вам профессиональный подход к вопросу, должное изучение ситуации и документов.'),
          t('Гарантируем конфиденциальность всей информации по вашему вопросу и делу.'),
          t(
            'Если у вас заключен договор на юридические услуги с "Лигл Офис", гарантируем, что смена юриста-исполнителя без согласования с вами не произойдёт!',
          ),
        ],
      ],
    },
    {
      question: t('Нужно ли приносить документы на юридическую консультацию?'),
      answer: [
        t(
          'Да! Мы, как юристы, оцениваем дело по документам - если они есть, значит есть и доказательства и факты. Если их нет - есть только слова. А человек может пересказать ситуацию по-разному, коверкая факты, придумывая обстоятельства - и это нормально для психолога! Но не для юриста!',
        ),
        t(
          'Юрист сопоставит ваш рассказ с документами и поймет, в чем на самом деле риски ситуации, а в чем - ее возможности! Всё это - предмет для продуктивной консультации, когда мы-юристы рассказываем вам, как ситуация разворачивается с точки зрения закона!',
        ),
        t(
          'Можете принести не документы-подлинники, а их ксерокопии. Многие люди опасаются носить с собой оригиналы документов.',
        ),
      ],
    },
  ];

  const REMOTE_FORM_ADVANTAGES = [
    {
      text: t('Абонентское юридическое обслуживание от 12 000 рублей/месяц'),
    },
    { text: t('Команда экспертов в разных областях права вместо 1 штатного юриста') },
    { text: t('Всегда рядом у в нужный момент. Не ходим в отпуск, не болеем, не увольняемся') },
  ];

  const INDIVIDUAL_SERVICES = [
    {
      text: t('Семейный юрист'),
    },
    {
      text: t('Автоюрист'),
    },
    {
      text: t('Защита прав потребителей'),
    },
    {
      text: t('Банкротство физ. лиц'),
    },
    {
      text: t('Споры с банками'),
    },
    {
      text: t('Жилищные вопросы'),
    },
    {
      text: t('Оформление наследства'),
    },
    {
      text: t('Споры со страховыми компаниями'),
    },
    {
      text: t('Трудовые споры'),
    },
  ];

  const LEGAL_SERVICES = [
    {
      text: t('Юридическое сопровождение'),
    },
    {
      text: t('Налоговый юрист'),
    },
    {
      text: t('Банкротство юр.лиц'),
    },
    {
      text: t('Ликвидация компаний'),
    },
    {
      text: t('Защита от субсидиарной ответственности'),
    },
  ];

  return {
    SERVICES,
    FORM_ADVANTAGES,
    LAWYER_ADVANTAGES,
    ABOUT_ADVANTAGES,
    EXPERTS,
    WORK_STEPS,
    FAQ,
    REMOTE_FORM_ADVANTAGES,
    INDIVIDUAL_SERVICES,
    LEGAL_SERVICES,
  };
};
