import Button from '../Button';
import './ModalSuccess.scss';
import PropTypes from 'prop-types';
import { SuccessIcon } from '../../icons';

const ModalSuccessContent = ({ onClose }) => {
  return (
    <div className={'modal-success'}>
      <div className={`modal-success__inner`}>
        <div className={'modal-success__title'}>Успех!</div>
        <div className={'modal-success__icon'}>
          <SuccessIcon />
        </div>
        <div className={'modal-success__description'}>
          Спасибо, ваша заявка принята. <br />В ближайшее время наш менеджер свяжется с вами.
        </div>
        <div className={'modal-success__action'}>
          <Button
            className={'modal-success__button'}
            title={'Спасибо!'}
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export default ModalSuccessContent;

ModalSuccessContent.propTypes = {
  onClose: PropTypes.func.isRequired,
};
