import './Services.scss';
import Section from '../../Section';
import Container from '../../Container';
import { useTranslation } from 'react-i18next';
import ServiceCard from '../../ServiceCard';
import { useTranslateData } from '../../../hooks/useTranslateData';

const Services = () => {
  const { t } = useTranslation();
  const { SERVICES } = useTranslateData();

  return (
    <Section className={'section-services'}>
      <Container className={'section-services__container'}>
        <div className={'section-services__inner'}>
          <div className={'section-services__part section-services__part-left'}>
            <h2 className={'section-services__title'}>{t('Наши услуги')}</h2>
            <p className={'section-services__description'}>
              {t(
                'Оказываем квалифицированную юридическую помощь организациям, гражданам, индивидуальным предпринимателям, предприятиям и учреждениям',
              )}
            </p>
          </div>

          <div className={'section-services__part section-services__part-center'}>
            <div className={'section-services__consultation-info consultation-info'}>
              <div className={'consultation-info__count'}>3500+</div>
              <div className={'consultation-info__text'}>{t('консультаций в год проводят наши юристы')}</div>
            </div>
          </div>

          <div className={'section-services__part section-services__part-right'}>
            <div className={'section-services__notification notification-info'}>
              <p>{t('Помните! К юристам лучше обращаться до возникновения или осложнения проблемы')}</p>
              <p>{t('Затягивание решения проблемы может сделать ее решение не только дороже, но и невозможным')}</p>
            </div>
          </div>
        </div>

        <div className={'section-services__services'}>
          <ul className={'our-services-list'}>
            {SERVICES.map((service) => (
              <li
                className={'our-services-list__item'}
                key={service.title}
              >
                <ServiceCard service={service} />
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </Section>
  );
};

export default Services;
