import { FormProvider } from 'react-hook-form';
import PropTypes from 'prop-types';

const FormWrapper = ({ children, onSubmit, methods, formClassName }) => {
  if (!methods) return null;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods?.handleSubmit(onSubmit)}
        className={formClassName}
      >
        {children}
      </form>
    </FormProvider>
  );
};

export default FormWrapper;

FormWrapper.propTypes = {
  methods: PropTypes.any,
  children: PropTypes.any,
  onSubmit: PropTypes.func,
  formClassName: PropTypes.string,
};
