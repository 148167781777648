import './PrivacyText.scss';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const PrivacyText = ({ className = '' }) => {
  const { t } = useTranslation();

  return (
    <div className={`${className} privacy-text`}>
      {t('Нажимая на кнопку вы соглашаетесь с обработкой персональных данных и политикой конфиденциальности')}
    </div>
  );
};

export default PrivacyText;

PrivacyText.propTypes = {
  className: PropTypes.string,
};
