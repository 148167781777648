import './FormAdvantages.scss';
import { CheckIcon } from '../../icons';
import PropTypes from 'prop-types';

const FormAdvantages = ({ className = '', direction = 'horizontal', advantages = [] }) => {
  return (
    <ul
      className={`${className} form-advantages-list 
    ${direction ? `form-advantages-list--${direction}` : ''}`}
    >
      {advantages.map((adv) => (
        <li
          className={'form-advantages-list__item'}
          key={adv.text}
        >
          <div
            className={`form-advantage 
          ${direction ? `form-advantage--${direction}` : ''}`}
          >
            <div className={'form-advantage__icon'}>
              <CheckIcon />
            </div>
            <div className={'form-advantage__text'}>{adv.text}</div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default FormAdvantages;

FormAdvantages.propTypes = {
  className: PropTypes.string,
  advantages: PropTypes.array,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
};
