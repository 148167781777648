import './RemoteConsultation.scss';
import Section from '../../Section';
import Container from '../../Container';
import { useTranslation } from 'react-i18next';
import RemoteConsultationForm from '../../RemoteConsultationForm';

const RemoteConsultation = () => {
  const { t } = useTranslation();

  return (
    <Section
      className={'section-remote-consultation'}
      id={'blog'}
    >
      <Container>
        <div className={'section-remote-consultation__inner'}>
          <h2 className={'section-remote-consultation__title'}>
            <span>{t('Дистанционно сопровождаем бизнес')}</span> {t('из любого региона России')}
          </h2>
          <p className={'section-remote-consultation__description'}>
            {t('Возьмём на себя ежедневные юридические задачи, предотвратим потерю денег и защитим ваши интересы')}
          </p>

          <div className={'section-remote-consultation__form'}>
            <RemoteConsultationForm />
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default RemoteConsultation;
