import './OurWork.scss';
import Section from '../../Section';
import Container from '../../Container';
import { useTranslation } from 'react-i18next';
import { useTranslateData } from '../../../hooks/useTranslateData';

const OurWork = () => {
  const { t } = useTranslation();
  const { WORK_STEPS } = useTranslateData();

  return (
    <Section className={'section-our-work'}>
      <Container className={'section-our-work__container'}>
        <div className={'section-our-work__inner'}>
          <h2 className={'section-our-work__title'}>{t('Наша работа - планомерна, ее легко разделить на этапы:')}</h2>

          <ul className={'section-our-work__steps work-steps'}>
            {WORK_STEPS.map((step) => (
              <li
                key={step.title}
                className={'work-steps__item'}
              >
                <div className={'work-step'}>
                  <div className={'work-step__title'}>{step.title}</div>

                  {step.texts.map((text) => (
                    <p
                      key={text}
                      className={'work-step__text'}
                    >
                      {text}
                    </p>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </Section>
  );
};

export default OurWork;
