import { device } from './device';
import { WM_ID } from '../configs';

export const TOKENS = {
  webmaster_project_token: 'webmaster_project_token',
  webmaster_id: 'webmaster_id',
  phone: 'phone',
  googleRecaptcha: 'googleRecaptcha',
  apiUrl: 'apiUrl',
  locationApi: 'locationApi',
  googleMap: 'googleMap',
  widgetToken: 'widgetToken',
  yandexCounter: 'yandexCounter',
};

export const THIRD_PARTY_VARIABLES = {
  url: window.location.href,
  domain: window.location.host,
  device: device,
  webmaster_id: WM_ID,
};
