import './PhoneWithButtonCallback.scss';
import { useTranslation } from 'react-i18next';
import { TelIcon } from '../../icons';
import { useDispatch } from 'react-redux';
import { openCallbackModal } from '../../actions/modalActions';
import { useContext } from 'react';
import { CityContext } from '../../context/context';
import { unFormattedPhoneNumber } from '../../utils';
import { handlePhoneClick } from '../../helpers/analitics';

const PhoneWithButtonCallback = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { PHONE } = useContext(CityContext);

  const handleOpenCallbackModal = () => {
    dispatch(openCallbackModal());
  };

  return (
    <div className={'phone-with-button'}>
      <div className={'phone-with-button__phone-wrap'}>
        <TelIcon className={'phone-with-button__icon'} />
        <a
          className={'phone-with-button__phone'}
          href={`tel:+${unFormattedPhoneNumber(PHONE)}`}
          onClick={handlePhoneClick}
        >
          {PHONE}
        </a>
      </div>

      <button
        onClick={handleOpenCallbackModal}
        className={'phone-with-button__callback-btn'}
      >
        {t('Заказать звонок')}
      </button>
    </div>
  );
};

export default PhoneWithButtonCallback;
