import './Container.scss';
import PropTypes from 'prop-types';

const Container = ({ className = '', ...props }) => {
  return (
    <div
      {...props}
      className={`container ${className}`}
    />
  );
};

export default Container;

Container.propTypes = {
  className: PropTypes.string,
};
