export const REGION_ANSWERS = {
  yes: 'yes',
  no: 'no',
};

export const CURRENT_IP = 'currentIP';
export const CURRENT_LOCATION = 'currentRegion';
export const RECAPTCHA_ACTION = 'login';
export const LOCATIONS = 'locations';

export const MINIMUM_SCORE = 0.9;
export const PHONE_LENGTH = 12;

export const DEVELOPMENT = 'development';
export const PRODUCTION = 'production';
export const YANDEX_METRIC_GOAL = 'cplcash-form-lead';

export const DEFAULT_CITIES_LOCALE = 'ru_RU';

export const INPUT_RULES = {
  name: {
    required: {
      value: true,
      message: 'Введите имя',
    },
  },
  question: {
    required: {
      value: false,
      message: 'Введите вопрос',
    },
  },
  location: {
    required: {
      message: 'Введите или выберите регион или город',
      value: true,
    },
  },
};

export const LOCALES = {
  ru: 'ru',
  uk: 'uk',
};
