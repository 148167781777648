import { LS_KEY } from '../configs';

export function handlePhoneClick() {
  if (!window.gtag) {
    return;
  }
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  const hasFlag = localStorage.getItem(LS_KEY);

  if (isMobile && !hasFlag) {
    console.log('click_phone_link');
    window.gtag('event', 'click_phone_link');
    localStorage.setItem(LS_KEY, 'lead sent');
  }
}

export function handleSubmitLead() {
  if (!window.gtag) {
    return;
  }

  const hasFlag = localStorage.getItem(LS_KEY);

  if (!hasFlag) {
    console.log('submit_lead_form');
    window.gtag('event', 'submit_lead_form');
    localStorage.setItem(LS_KEY, 'lead sent');
  }
}
