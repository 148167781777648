import './Question.scss';
import { useTranslation } from 'react-i18next';
import Section from '../../Section';
import Container from '../../Container';
import Cube from '../../../images/cube.png';
import { useDispatch } from 'react-redux';
import { openCallbackModal } from '../../../actions/modalActions';

const Question = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOpenCallbackModal = () => {
    dispatch(openCallbackModal());
  };

  return (
    <Section
      className={'section-question'}
      color={'red'}
    >
      <Container className={'section-question__container'}>
        <div className={'section-question__inner'}>
          <div className={'section-question__info'}>
            <h3 className={'section-question__title'}>{t('Спросить быстрее, чем искать!')}</h3>
            <p className={'section-question__description'}>
              {t('Укажите ваш телефон мы сами свяжемся с вами и решим ваш вопрос!')}
            </p>
          </div>
          <div className={'section-question__action'}>
            <button
              onClick={handleOpenCallbackModal}
              className={'section-question__ask-btn'}
            >
              {t('Спросить!')}
            </button>
            <img
              className={'section-question__image'}
              src={Cube}
              alt='Ask'
            />
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Question;
