import PropTypes from 'prop-types';
import './Button.scss';
import { useTranslation } from 'react-i18next';

const Button = ({
  size,
  className,
  activeClassName,
  submit,
  title,
  icon: Icon,
  style,
  fullWidth,
  children,
  disabled,
  ghost,
  active,
  onClick,
  animation,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <button
      onClick={onClick}
      className={`button 
                ${active ? `${activeClassName}` : ''}
                ${Icon ? 'button--has-icon' : ''}
                ${submit ? 'button--submit' : ''}
                ${fullWidth ? 'button--full-width' : ''}
                ${disabled ? 'button--disabled' : ''}
                ${ghost ? 'button--ghost' : ''}
                ${size ? `button--${size}` : ''}
               ${className}
                `}
      style={{ ...style }}
      {...props}
    >
      {Icon && (
        <div className='button__icon'>
          <Icon />
        </div>
      )}

      <div className='button__text'>{t(title)}</div>

      {animation && (
        <div className={'animation-line-wrap'}>
          <div className={'animation-line'}></div>
        </div>
      )}

      {children}
    </button>
  );
};

export default Button;

Button.defaultProps = {
  size: 'small',
  className: '',
  activeClassName: 'button--active',
  submit: false,
  title: '',
  icon: null,
  style: {},
  fullWidth: false,
  disabled: false,
  ghost: false,
  active: false,
  onClick: () => {},
  animation: false,
};

Button.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  submit: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.element,
  style: PropTypes.object,
  fullWidth: PropTypes.bool,
  children: PropTypes.any,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  ghost: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'big', 'large']),
  animation: PropTypes.bool,
};
