import './RemoteConsultationForm.scss';
import { FormWrapper, Input, InputPhone } from '../Form';
import Button from '../Button';
import PrivacyText from '../PrivacyText';
import FormAdvantages from '../FormAdvantages';
import { useForm } from 'react-hook-form';
import { useTranslateData } from '../../hooks/useTranslateData';

const RemoteConsultationForm = () => {
  const { REMOTE_FORM_ADVANTAGES } = useTranslateData();
  const { ...methods } = useForm();

  const handleSubmit = (data) => {
    console.log(data);
  };
  return (
    <FormWrapper
      formClassName={`remote-consultation-form`}
      onSubmit={handleSubmit}
      methods={methods}
    >
      <div className={'remote-consultation-form__inner'}>
        <div className={'remote-consultation-form__fields'}>
          <Input
            className={'search-form__field'}
            placeholder={'Как к вам обращаться?'}
            name={'name'}
            type={'text'}
            fullWidth
          />

          <InputPhone variant={'default'} />

          <Button
            title={'Узнать подробнее'}
            size={'big'}
          />
        </div>

        <PrivacyText className={'remote-consultation-form__privacy'} />

        <FormAdvantages
          className={'remote-consultation-form__advantages'}
          advantages={REMOTE_FORM_ADVANTAGES}
        />
      </div>
    </FormWrapper>
  );
};

export default RemoteConsultationForm;
