import { Fragment } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import './Input.scss';
import { useTranslation } from 'react-i18next';

const Input = ({
  icon: Icon = () => null,
  variant,
  size,
  type = 'text',
  className,
  multiline,
  name,
  rows,
  fullWidth,
  rules,
  placeholder,
  ...props
}) => {
  const { control } = useFormContext();
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={''}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <div className={`input__root ${className}`}>
          {multiline ? (
            <textarea
              name={name}
              {...field}
              {...props}
              placeholder={t(placeholder)}
              rows={rows}
              className={`input input--multiline 
                           ${variant ? `input--${variant}` : ''}
                          ${error ? 'input--invalid' : ''}`}
            />
          ) : (
            <Fragment>
              <Icon
                className={`input__icon 
                                ${error ? 'input__icon--invalid' : ''}`}
              />
              <input
                name={name}
                type={type}
                placeholder={t(placeholder)}
                {...field}
                {...props}
                className={`input ${error ? 'input--invalid' : ''}
                                 ${size ? `input--${size}` : ''}
                                 ${variant ? `input--${variant}` : ''}
                                 ${fullWidth ? `input--full-width` : ''}
                                 `}
                autoComplete='off'
              />
            </Fragment>
          )}
          {error && <div className='input__error'>{error?.message}</div>}
        </div>
      )}
    />
  );
};

export default Input;

Input.defaultProps = {
  icon: () => {},
  size: 'md',
  variant: 'default',
  type: 'text',
  className: '',
  error: '',
  multiline: false,
  name: '',
  rows: 0,
  fullWidth: false,
  rules: {},
};

Input.propTypes = {
  icon: PropTypes.func,
  variant: PropTypes.oneOf(['default', 'blue']),
  size: PropTypes.oneOf(['es', 'md']),
  type: PropTypes.oneOf(['text', 'number', 'tel']),
  className: PropTypes.string,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  rows: PropTypes.number,
  fullWidth: PropTypes.bool,
  rules: PropTypes.object,
  placeholder: PropTypes.string,
};
