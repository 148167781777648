import './Footer.scss';
import { useTranslation } from 'react-i18next';
import Container from '../../Container';
import Logo from '../../Logo/Logo';
import PhoneWithButtonCallback from '../../PhoneWithButtonCallback';
import { useContext } from 'react';
import { CityContext } from '../../../context/context';

const Footer = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { CITY_NAME } = useContext(CityContext);

  return (
    <footer className={'footer'}>
      <Container>
        <div className={'footer__inner'}>
          <div className={'footer__logo'}>
            <Logo className={'footer__logo-img'} />
            <div className={'footer__logo-text'}>
              {t('Консультация и услуги юристов в', { city: CITY_NAME[language].PREDLOJ })}
            </div>
          </div>

          <div className={'footer__phone'}>
            <PhoneWithButtonCallback />
          </div>
        </div>
        <div className={'footer__policy'}>{t('Политика конфиденциальности')}</div>
      </Container>
    </footer>
  );
};

export default Footer;
