import { useForm } from 'react-hook-form';
import './SearchForm.scss';
import { useTranslation } from 'react-i18next';
import FormBorderWrap from '../FormBorderWrap';
import { FormWrapper, Input, InputPhone } from '../Form';
import Button from '../Button';
import FormAdvantages from '../FormAdvantages';
import PrivacyText from '../PrivacyText';
import { useTranslateData } from '../../hooks/useTranslateData';
import { sendFormDataToLP } from '../../services/api';
import { useContext } from 'react';
import { CityContext } from '../../context/context';

const OfferForm = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { FORM_ADVANTAGES } = useTranslateData();
  const { ...methods } = useForm();
  const { CITY_NAME } = useContext(CityContext);

  const handleSubmit = async (data) => {
    sendFormDataToLP(data, language);
  };

  return (
    <FormWrapper
      formClassName={'search-form'}
      onSubmit={handleSubmit}
      methods={methods}
    >
      <FormBorderWrap>
        <div className={'search-form__inner'}>
          <div className={'search-form__info'}>
            <h2 className={'search-form__title'}>
              <span>{t('Не нашли что искали?')}</span>
              <br />
              {t('searchFormTitle', { city: CITY_NAME[language].PREDLOJ })}
            </h2>
            <FormAdvantages
              advantages={FORM_ADVANTAGES}
              className={'search-form__advantages'}
              direction={'vertical'}
            />
          </div>

          <div className={'search-form__fields'}>
            <Input
              className={'search-form__field'}
              placeholder={'Как к вам обращаться?'}
              name={'name'}
              type={'text'}
              fullWidth
            />

            <InputPhone
              className={'search-form__field'}
              variant={'default'}
            />

            <Input
              className={'search-form__field'}
              placeholder={'Опишите вашу ситуация (не обязательно)'}
              name={'question'}
              type={'text'}
              fullWidth
              multiline
              rows={5}
            />

            <Button
              className={'search-form__submit'}
              title={'Подобрать юриста'}
              size={'big'}
            />
            <PrivacyText className={'search-form__privacy'} />
          </div>
        </div>
      </FormBorderWrap>
    </FormWrapper>
  );
};

export default OfferForm;
