import './Menu.scss';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { uuid } from '../../utils';
import { useTranslation } from 'react-i18next';

const MENU_ITEMS = [
  {
    name: 'Блог',
    anchor: '#blog',
  },
  {
    name: 'О Компании',
    anchor: '#about',
  },
  {
    name: 'Отзывы',
    anchor: '#reviews',
  },
  {
    name: 'Контакты',
    anchor: '#contacts',
  },
];

const Menu = ({ className = '', mobileMenuOpen, handleMobileMenu }) => {
  const location = useLocation();
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const handleClick = () => {
    if (mobileMenuOpen) {
      handleMobileMenu();
    }
  };

  function handleChangeLang() {
    changeLanguage(language === 'ru' ? 'uk' : 'ru');
  }

  return (
    <ul className={`menu ${className}`}>
      {MENU_ITEMS.map((item) => (
        <li
          className={`menu__item`}
          key={uuid()}
        >
          <a
            onClick={handleClick}
            className={`menu__link 
                      ${location.hash === item.anchor ? 'active' : ''}`}
            href={item.anchor}
            // onClick={handleScrollToAnchor}
          >
            {t(item.name)}
          </a>
        </li>
      ))}
      <button
        type='button'
        onClick={handleChangeLang}
      >
        LNG
      </button>
    </ul>
  );
};

export default Menu;

Menu.propTypes = {
  handleMobileMenu: PropTypes.func,
  mobileMenuOpen: PropTypes.bool,
  className: PropTypes.string,
};
