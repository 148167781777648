import './ServiceCard.scss';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { openCallbackModal } from '../../actions/modalActions';

const ServiceCard = ({ service }) => {
  const dispatch = useDispatch();

  const handleOpenCallbackModal = () => {
    dispatch(openCallbackModal());
  };

  return (
    <div
      className={'service-card'}
      role={'button'}
      tabIndex={-1}
      onKeyDown={handleOpenCallbackModal}
      onClick={handleOpenCallbackModal}
    >
      <div className={'service-card__inner'}>
        <div className={'service-card__info'}>
          <h3 className={'service-card__title'}>{service.title}</h3>
          <div className={'service-card__icon'}>{service.icon}</div>
        </div>
        <div className={'service-card__description'}>{service.description}</div>
      </div>
    </div>
  );
};

export default ServiceCard;

ServiceCard.propTypes = {
  service: PropTypes.exact({
    icon: PropTypes.node,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
};
