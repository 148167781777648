import './Modal.scss';
import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { CloseIcon } from '../../icons';
import PropTypes from 'prop-types';

ReactModal.setAppElement('#root');

const Modal = ({
  size,
  children = null,
  isOpen = false,
  withoutPadding = false,
  onRequestClose = () => {},
  ...props
}) => {
  const [scrollWidth, setScrollWidth] = useState(0);

  useEffect(() => {
    const clientScrollWidth = window.innerWidth - document.documentElement.offsetWidth;
    setScrollWidth(clientScrollWidth);
  }, [scrollWidth]);

  let handleAfterOpen = () => {
    const documentHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (clientHeight < documentHeight) {
      document.body.style.paddingRight = `${scrollWidth}px`;
    }

    document.body.style.overflow = 'hidden';
  };

  let handleAfterClose = () => {
    document.body.style.paddingRight = '0';
    document.body.style.overflow = 'unset';
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onAfterOpen={handleAfterOpen}
      onAfterClose={handleAfterClose}
      {...props}
      closeTimeoutMS={200}
      onRequestClose={onRequestClose}
      className={`modal 
                  ${size ? `modal--${size}` : ''}
                  `}
      overlayClassName='overlay'
    >
      <div className='modal__content'>
        <CloseIcon
          className='modal__close'
          onClick={onRequestClose}
        />
        {/*<div className="modal__header">*/}
        {/*  {title}*/}
        {/*</div>*/}

        <div
          className={`modal__body
                        ${withoutPadding ? `modal__body--without-padding` : ''}`}
        >
          {children}
        </div>
      </div>
    </ReactModal>
  );
};

export default Modal;

Modal.propTypes = {
  size: PropTypes.oneOf(['xs', 'sm']),
  children: PropTypes.any,
  // title: PropTypes.string,
  isOpen: PropTypes.bool,
  withoutPadding: PropTypes.bool,
  onRequestClose: PropTypes.func,
};
