import { ReactComponent as LogoRuWhite } from './leegl-office-white.svg';
import { ReactComponent as LogoUaWhite } from './leegl-office-ua-white.svg';

export const LogoRuWhiteIcon = (props) => {
  return <LogoRuWhite {...props} />;
};

export const LogoUaWhiteIcon = (props) => {
  return <LogoUaWhite {...props} />;
};
