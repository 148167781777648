import './HelpLawyer.scss';
import { useTranslation } from 'react-i18next';
import Section from '../../Section';
import Container from '../../Container';
import OurAdvantages from '../../OurAdvantages';
import { useContext } from 'react';
import { CityContext } from '../../../context/context';

const HelpLawyer = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { CITY_NAME } = useContext(CityContext);

  return (
    <Section className={'section-help-lawyer'}>
      <Container className={'section-help-lawyer__container'}>
        <div className={'section-help-lawyer__inner'}>
          <div className={'section-help-lawyer__lawyer-advantages'}>
            <OurAdvantages />
          </div>

          <div className={'section-help-lawyer__info'}>
            <div className={'section-help-lawyer__info-left'}>
              <h2 className={'section-help-lawyer__title'}>
                {t('lawyerHelp', {
                  city: CITY_NAME[language].PREDLOJ,
                })}
              </h2>
              <p className={'section-help-lawyer__description'}>
                {t('Именно в момент, когда вы не знаете, что делать, знайте – вам нужна консультация юриста!')}
              </p>
            </div>

            <div className={'section-help-lawyer__info-right'}>
              <p className={'section-help-lawyer__text'}>
                {t(
                  'Рано или поздно но случаются и конфликты в семье и тяжелое положение с финансами и затруднительная ситуация на работе и проблемы с жильём.',
                )}
                &nbsp;
                {t('Приходится решать - что делать, как именно действовать, каким может быть эффект...')}
              </p>
              <p className={'section-help-lawyer__text'}>
                {t('Лучше всего в этот момент найти')}&nbsp;
                <strong>
                  {t('юриста в')} {CITY_NAME[language].PREDLOJ}
                </strong>
                ,&nbsp;
                {t(
                  'кто мог бы дать совет, предостеречь, или оказать реальную помощь - составить документы, защитить ваши права в суде, проверить сделку, пройти инстанции',
                )}
                ...
              </p>
              <p className={'section-help-lawyer__text'}>
                {t('Мы, юристы «Лигл Офис» - готовы помочь! Мы не обманем, не подставим, не скроем информацию.')}
                {t('Если можно чем-то помочь - расскажем и поможем. Если нельзя помочь - расскажем почему!')}
                {t('Мы ценим ваше время, и наше доброе имя.')}
              </p>
              <p className={'section-help-lawyer__text'}>{t('Звоните, приходите, задавайте вопросы!')}</p>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default HelpLawyer;
