import './About.scss';
import { useTranslation } from 'react-i18next';
import Section from '../../Section';
import Container from '../../Container';
import Lawyer from '../../../images/lawyer.jpg';
import { useTranslateData } from '../../../hooks/useTranslateData';
import { useContext } from 'react';
import { CityContext } from '../../../context/context';

const About = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { ABOUT_ADVANTAGES } = useTranslateData();
  const { CITY_NAME } = useContext(CityContext);
  return (
    <Section
      className={'section-about'}
      id={'about'}
    >
      <Container className={'section-about__container'}>
        <div className={'section-about__inner'}>
          <div className={'section-about__info'}>
            <div className={'section-about__photo'}>
              <img
                src={Lawyer}
                alt={t('Лигл Офисы')}
              />
            </div>
            <div className={'section-about__text'}>
              <h2 className={'section-about__title'}>
                {t('Компания')} <span>«{t('Лигл Офисы')}»</span> {t('юристы в')} {CITY_NAME[language].PREDLOJ}
              </h2>

              <p className={'section-about__text section-about__text--quote'}>
                &quot;
                {t(
                  'Мы строим бизнес с человеческим лицом – открытый и честный, основанный на доверии и долгих партнерских отношениях с клиентами',
                )}
                &quot;
              </p>

              <p className={'section-about__text'}>
                {t('«Лигл Офисы» – компания, где собран уникальный состав опытных юристов, адвокатов, финансистов.')}
                {t(
                  'Все они имеют большую профессиональную практику и работают в различных направлениях права и экономики.',
                )}
              </p>

              <p className={'section-about__text'}>
                {t('Именно поэтому мы предлагаем нашим Клиентам полный спектр услуг!')}
              </p>

              <p className={'section-about__text'}>
                {t(
                  '— «Лигл Офисы» - крупнейшая компания нашего региона и лидер всероссийского рейтинга компаний «ПРАВО-300»,',
                )}
              </p>

              <p className={'section-about__text'}>
                {t(
                  '— «Лигл Офисы» - признан порталом DK.RU ("Деловой квартал") - мы возглавляем список рейтинга юридических компаний.',
                )}
              </p>
              <p className={'section-about__text'}>
                {t(
                  'Не только в нашем регионе, но и по всей России мы имеем партнерские связи с юридическими компаниями, гос. органами, лицензионными специалистами: экспертами, оценщиками, создателями проектов, кадастровыми инженерами, арбитражными управляющими и аудиторами.',
                )}
              </p>

              <p className={'section-about__text'}>
                {t(
                  'Для наших клиентов - это гарантия результата или шансов на успех в суде, коротких сроков исполнения задачи, максимально комфортных условий сотрудничества.',
                )}
              </p>
            </div>
          </div>

          <div className={'section-about__advantages'}>
            <ul className={'about-advantages'}>
              {ABOUT_ADVANTAGES.map((item) => (
                <li
                  className={'about-advantages__item'}
                  key={item.count}
                >
                  <div className={'about-advantage'}>
                    <div className={'about-advantage__count'}>{item.count}</div>
                    <div className={'about-advantage__text'}>{item.text}</div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default About;
