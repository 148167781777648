import './Search.scss';
import Section from '../../Section';
import Container from '../../Container';
import SearchForm from '../../SearchForm';

const Search = () => {
  return (
    <Section className={'section-search'}>
      <Container className={'section-search__container'}>
        <div className={'section-search__inner'}>
          <SearchForm />
        </div>
      </Container>
    </Section>
  );
};

export default Search;
