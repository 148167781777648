const CITIES_DATA = {
  KYIV: {
    CITY_NAME: {
      ru: {
        IMENIT: 'Киев',
        RODIT: 'Киева',
        PREDLOJ: 'Киеве',
      },
      uk: {
        IMENIT: 'Київ',
        RODIT: 'Києва',
        PREDLOJ: 'Києві',
      },
    },
    PHONE: '+38 (044) 321-14-43',
  },
  ODESA: {
    CITY_NAME: {
      ru: {
        IMENIT: 'Одесса',
        RODIT: 'Одессы',
        PREDLOJ: 'Одессе',
      },
      uk: {
        IMENIT: 'Одеса',
        RODIT: 'Одеси',
        PREDLOJ: 'Одесі',
      },
    },
    PHONE: '+38 (048) 734-06-61',
  },
  LVIV: {
    CITY_NAME: {
      ru: {
        IMENIT: 'Львов',
        RODIT: 'Львова',
        PREDLOJ: 'Львове',
      },
      uk: {
        IMENIT: 'Львів',
        RODIT: 'Львова',
        PREDLOJ: 'Львові',
      },
    },
    PHONE: '+38 (032) 229-50-31',
  },
  KHARKIV: {
    CITY_NAME: {
      ru: {
        IMENIT: 'Харьков',
        RODIT: 'Харькова',
        PREDLOJ: 'Харькове',
      },
      uk: {
        IMENIT: 'Харків',
        RODIT: 'Харкова',
        PREDLOJ: 'Харкові',
      },
    },
    PHONE: '+38 (057) 727-53-64',
  },
  DNIPRO: {
    CITY_NAME: {
      ru: {
        IMENIT: 'Днепр',
        RODIT: 'Днепра',
        PREDLOJ: 'Днепре',
      },
      uk: {
        IMENIT: 'Дніпро',
        RODIT: 'Дніпра',
        PREDLOJ: 'Дніпрі',
      },
    },
    PHONE: '+38 (044) 321-14-43',
  },
};

export { CITIES_DATA };
