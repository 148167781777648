import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Header.scss';
import Logo from '../../Logo/Logo';
import Container from '../../Container';
import Menu from '../../Menu';
import PhoneWithButtonCallback from '../../PhoneWithButtonCallback';
import { useDispatch } from 'react-redux';
import { openCallbackModal } from '../../../actions/modalActions';
import { CityContext } from '../../../context/context';
import { unFormattedPhoneNumber } from '../../../utils';
import { handlePhoneClick } from '../../../helpers/analitics';

const Header = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const [scrolled, setScrolled] = useState(false);
  const { PHONE, CITY_NAME } = useContext(CityContext);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const windowWidth = window.innerWidth;
    const MIN_HEIGHT_SCROLL = 100;
    if (windowWidth < 1000 || scrollTop >= MIN_HEIGHT_SCROLL) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', isSticky);
    window.addEventListener('resize', isSticky);
    return () => {
      document.removeEventListener('scroll', isSticky);
      window.removeEventListener('resize', isSticky);
    };
  });

  const handleOpenCallbackModal = () => {
    dispatch(openCallbackModal());
  };

  return (
    <header className={`header ${scrolled ? 'header--scrolled' : ''}`}>
      <Container>
        <div className={'header__inner'}>
          <div className={'header__logo'}>
            <Logo className={'header__logo-img'} />
            <div className={'header__logo-text'}>
              {!scrolled ? (
                <>
                  {t('Юридические услуги для физических и юридических лиц в', {
                    city: CITY_NAME[language].PREDLOJ,
                  })}
                </>
              ) : (
                <>{t('Консультация и услуги юристов в', { city: CITY_NAME[language].PREDLOJ })} </>
              )}
            </div>
          </div>

          {scrolled ? null : <Menu className={'header__menu'} />}

          {scrolled ? (
            <PhoneWithButtonCallback />
          ) : (
            <div className={'header__callback'}>
              <div className={'phone-info'}>
                <a
                  className={'phone-info__tel'}
                  href={`tel:+${unFormattedPhoneNumber(PHONE)}`}
                  onClick={handlePhoneClick}
                >
                  {PHONE}
                </a>
                <button
                  onClick={handleOpenCallbackModal}
                  className={'phone-info__callback'}
                >
                  {t('Обратный звонок')}
                </button>
              </div>
            </div>
          )}
        </div>
      </Container>
    </header>
  );
};

export default Header;
