import PhoneInput from 'react-phone-input-2';
import { Controller, useFormContext } from 'react-hook-form';
import 'react-phone-input-2/lib/style.css';
import './InputPhone.scss';
import PropTypes from 'prop-types';
import { getCurrLocale } from '../../../utils';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line react/display-name
const InputPhone = ({ className, variant = 'default' }) => {
  const { t } = useTranslation();

  const { control } = useFormContext();
  return (
    <Controller
      name='phone'
      control={control}
      rules={{
        required: {
          value: true,
          message: t('Введите номер телефона'),
        },
        minLength: {
          value: 12,
          message: t('Введите номер телефона полностью'),
        },
      }}
      render={({ field: { ref, ...field }, fieldState: { invalid, error } }) => (
        <div
          className={`input-phone-wrapper 
                          ${className}
                          ${variant ? `input-phone-wrapper--${variant}` : ''}`}
        >
          <PhoneInput
            {...field}
            countryCodeEditable={true}
            country={getCurrLocale(process.env.REACT_APP_LANGUAGE)}
            disableDropdown
            inputExtraProps={{
              ref,
              required: true,
              autoFocus: true,
            }}
            isValid={!invalid}
          />
          {error && <p className={'input-phone__error'}>{error.message}</p>}
        </div>
      )}
    />
  );
};

export default InputPhone;

InputPhone.propTypes = {
  variant: PropTypes.oneOf(['default', 'blue']),
  className: PropTypes.string,
};
