import PropTypes from 'prop-types';
import './PhoneLink.scss';
import { unFormattedPhoneNumber } from '../../utils';
import { PHONE_LENGTH } from '../../helpers/constants';
import { handlePhoneClick } from '../../helpers/analitics';
import { useContext } from 'react';
import { CityContext } from '../../context/context';

const PhoneLink = ({ className }) => {
  const { PHONE } = useContext(CityContext);

  let linkPhone = unFormattedPhoneNumber(PHONE);

  if (linkPhone.length !== PHONE_LENGTH) {
    console.error(`${linkPhone} phone length !== ${PHONE_LENGTH}`);
    linkPhone = linkPhone.slice(0, PHONE_LENGTH);
    linkPhone = linkPhone.padEnd(PHONE_LENGTH, 0);
  }

  return (
    <>
      {linkPhone ? (
        <a
          className={`phone-link ${className}`}
          href={`tel:+${linkPhone}`}
          onClick={handlePhoneClick}
        >
          {PHONE}
        </a>
      ) : null}
    </>
  );
};

export default PhoneLink;

PhoneLink.propTypes = {
  className: PropTypes.string,
};
