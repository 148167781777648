import ModalCallback from '../../components/ModalCallback';
import ModalSuccess from '../../components/ModalSuccess';
import {
  About,
  AboutLawyers,
  Header,
  HelpLawyer,
  LeadingExperts,
  Offer,
  OurWork,
  Question,
  Search,
  Services,
  Faq,
  RemoteConsultation,
  Contacts,
  Footer,
} from '../../components/Sections';
import { CityContext } from '../../context/context';
import { CITIES_DATA } from '../../context/CitiesData';

const MainPage = () => {
  return (
    <CityContext.Provider value={CITIES_DATA.KYIV}>
      <Header />
      <Offer />
      <Services />
      <Search />
      <Question />
      <HelpLawyer />
      <About />
      <LeadingExperts />
      <AboutLawyers />
      <OurWork />
      <Faq />
      <RemoteConsultation />
      <Contacts />
      <Footer />
      <ModalCallback />
      <ModalSuccess />
    </CityContext.Provider>
  );
};

export default MainPage;
