import PropsTypes from 'prop-types';
import LogoRu from '../../images/leegl-office.svg';
import LogoUk from '../../images/leegl-office-ua.svg';
import { useTranslation } from 'react-i18next';

const Logo = ({ className = '', ...props }) => {
  const { i18n } = useTranslation();

  return (
    <a
      href={'/'}
      className={`${className}`}
      {...props}
    >
      {i18n.language === 'ru' && (
        <img
          src={LogoRu}
          alt='Logo'
        />
      )}

      {i18n.language === 'uk' && (
        <img
          src={LogoUk}
          alt='Logo'
        />
      )}
    </a>
  );
};

export default Logo;

Logo.propTypes = {
  className: PropsTypes.string,
};
