const WM_ID = 19719;

const WIDGET_CODE = {
  uk: '!function(e,d){if(void 0===e.lp_46843474cf){var c=d.createElement("script");c.type="text/javascript",c.src="/proxy_100d401be2.php?init_widget=3582ab4579a39a4fbdc69446843474cf&wm_id=19719",c.charset="UTF-8",d.body.appendChild(c)}}(window,document);',
  ru: '!function(e,d){if(void 0===e.lp_969022d6fe){var c=d.createElement("script");c.type="text/javascript",c.src="/proxy_100d401be2.php?init_widget=371ed0f8ee30a8b29cfd24969022d6fe&wm_id=19719",c.charset="UTF-8",d.body.appendChild(c)}}(window,document);',
};

const WIDGET_SCRIPT = {
  uk: '/proxy_100d401be2.php?init_widget=3582ab4579a39a4fbdc69446843474cf&wm_id=19719',
  ru: '/proxy_100d401be2.php?init_widget=371ed0f8ee30a8b29cfd24969022d6fe&wm_id=19719',
};

const TEXT_DEFAULT_IN_LEAD = {
  uk: 'Запит зворотного дзвінка',
  ru: 'Запрос обратного звонка',
};

const LOCATION_DEFAULT_IN_LEAD = 'Киев';

const NAME_DEFAULT_IN_LEAD = 'тест';

const LS_KEY = 'develop';

export {
  WIDGET_CODE,
  WM_ID,
  TEXT_DEFAULT_IN_LEAD,
  LS_KEY,
  NAME_DEFAULT_IN_LEAD,
  LOCATION_DEFAULT_IN_LEAD,
  WIDGET_SCRIPT,
};
