import './FormBorderWrap.scss';
import PropsTypes from 'prop-types';

const FormBorderWrap = ({ children }) => {
  return (
    <div className={'form-wrapper'}>
      <div className={'form-wrapper__border'}>{children}</div>
    </div>
  );
};

export default FormBorderWrap;

FormBorderWrap.propTypes = {
  children: PropsTypes.node,
};
